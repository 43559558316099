import React, { memo, useEffect, useMemo } from "react"

import { useApp } from "../../hooks/useApp"

export const withSearch = Component =>
  memo(({ name = "Search", global, page, template }: any) => {
    const {
      searchKey,
      setSearchKey,
      config: { store },
    } = useApp()

    const shopifyPermanentDomain = `${store.shopName}.myshopify.com`

    useEffect(() => {
      if (searchKey !== `search`) setSearchKey(`search`)
    }, [searchKey, setSearchKey])

    Component.displayName = name
    return useMemo(
      () => <Component global={global} page={page} shopifyPermanentDomain={shopifyPermanentDomain} template={template} />,
      [global, page, shopifyPermanentDomain, template]
    )
  })
