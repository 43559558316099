import React, { memo, useCallback, useMemo } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useCart } from "../../../../hooks/useCart"
import { useCheckoutContext } from "../../../../hooks/useCheckout"
import { useDom } from "../../../../hooks/useDom"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withFeaturedProductsItem = Component =>
  memo(({ name = "FeaturedProductsItem", handlePromoClick = null, item, pretitle = null, tag, top }: any) => {
    const { setActiveProductNotify } = useApp()
    const { addToCart, loading: adding } = useCart()
    const { checkout } = useCheckoutContext()
    const { dom } = useDom()
    const { cart, product } = useTemplate()

    const image = item?.images?.[4] || item?.images?.[0]
    const variant = item?.variants?.find(({ available }) => available) || item?.variants?.[0]
    const added = checkout?.lineItems?.find(lineItem => lineItem?.variant?.id === variant?.id)
    const aspectRatio = dom?.isLarge ? 20 / 23 : 1 / 1

    const locales = useMemo(
      () => ({
        ...product,
        ...cart,
      }),
      [cart, product]
    )

    const handleAdd = useCallback(async () => {
      if (handlePromoClick) handlePromoClick()

      await addToCart(variant?.id, 1)
    }, [addToCart, handlePromoClick, variant])

    const handleNotify = useCallback(() => setActiveProductNotify(item?.handle), [item, setActiveProductNotify])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          added={added}
          adding={adding}
          aspectRatio={aspectRatio}
          handleAdd={handleAdd}
          handleNotify={handleNotify}
          handlePromoClick={handlePromoClick}
          image={image}
          item={item}
          locales={locales}
          pretitle={pretitle}
          tag={tag}
          top={top}
          variant={variant}
        />
      ),
      [added, adding, aspectRatio, handleAdd, handleNotify, handlePromoClick, image, item, locales, pretitle, tag, top, variant]
    )
  })
