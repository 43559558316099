import tw, { styled } from "twin.macro"

import { StyledLink } from "../../../Styled/Button"

export const Arrow = styled(StyledLink)`
  ${tw`absolute z-1 w-6 h-6 md:w-8 md:h-8 flex items-center justify-center`}
  ${({ position }) => (position === `right` ? tw`right-0` : tw`left-0`)}
`
export const Arrows = tw.div`relative mt-1/2 lg:mt-0`
export const ArrowWrapper = tw.div`absolute inset-0 flex items-start lg:items-center justify-center`
