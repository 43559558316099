import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withFeaturedProductsItem } from "./withFeaturedProductsItem"
import { StyledButton } from "../../../Styled/Button"
import { StyledContainer } from "../../../Styled/Container"
import { StyledRow } from "../../../Styled/Row"
import { P } from "../../../Styled/Text"
import {
  AspectRatio,
  Background,
  Column,
  Content,
  Image,
  Item,
  Link,
  Button,
  Heading,
  Pretitle,
  Price,
  ComparedPrice,
  Slide,
  Title,
} from "./styledFeaturedProductsItem"

export const FeaturedProductsItem = withFeaturedProductsItem(
  ({ added, adding, aspectRatio, handleAdd, handleNotify, handlePromoClick, image, item, locales, pretitle, tag, top, variant }): JSX.Element => (
    <Slide>
      {pretitle && <Heading background={item?.colour}>{pretitle}</Heading>}
      <Background items={`stretch`} withWrap>
        <Column colour={item?.colour} width={`lg:1/2`} />
        <Column colour={`${item?.colour}-pastel`} width={`lg:1/2`} />
      </Background>
      <StyledContainer collapse={`<md`} width={`xl`}>
        <Item>
          <StyledRow items={`center`} withWrap>
            <Column colour={item?.colour} width={`lg:1/2`}>
              <Link as={GatsbyLink} onClick={handlePromoClick} title={locales?.additionalRelatedView} to={item?.url}>
                {image ? (
                  <Image alt={image?.alt || item?.title} empty={!image} fluid={{ ...image, aspectRatio }} top={top} />
                ) : (
                  <AspectRatio top={top} />
                )}
              </Link>
            </Column>
            <Column colour={`${item?.colour}-pastel`} items={`center`} justify={`center`} vertical width={`lg:1/2`}>
              <Content>
                {pretitle && (
                  <Pretitle colour={item?.colour} large withSpacing>
                    {pretitle}
                  </Pretitle>
                )}
                <Link as={GatsbyLink} onClick={handlePromoClick} title={locales?.additionalRelatedView} to={item?.url}>
                  <Title as={tag} uppercase withSpacing={`xxs`}>
                    {item?.title}
                  </Title>
                </Link>
                <P large withSpacing={`xxs`}>
                  {item?.type}
                </P>
                <Price as={`p`} withSpacing={`md`}>
                  {item?.giftcard && variant?.compareAtPriceV2?.amount && variant?.priceV2?.amount > variant?.compareAtPriceV2?.amount ? (
                    <>
                      <ComparedPrice>&nbsp;{variant?.priceV2?.local}&nbsp;</ComparedPrice>
                      {variant?.compareAtPriceV2?.local}
                    </>
                  ) : (
                    <>
                      {variant?.compareAtPriceV2?.amount > variant?.priceV2?.amount ? (
                        <ComparedPrice>&nbsp;{variant?.compareAtPriceV2?.local}&nbsp;</ComparedPrice>
                      ) : null}
                      {variant?.priceV2?.local}
                    </>
                  )}
                </Price>
                <StyledButton
                  colour={variant?.availableForSale ? `orange` : `white`}
                  disabled={adding}
                  full
                  onClick={variant?.availableForSale ? handleAdd : handleNotify}
                  size={`primary`}
                  title={
                    variant?.availableForSale ? (added ? locales?.additionalAddedToCart : locales?.additionalAddToCart) : locales?.additionalNotify
                  }
                  withSpacing={`xs`}
                >
                  {variant?.availableForSale ? (added ? locales?.additionalAddedToCart : locales?.additionalAddToCart) : locales?.additionalNotify}
                </StyledButton>
                <Button
                  as={GatsbyLink}
                  colour={`grey-darker`}
                  onClick={handlePromoClick}
                  title={locales?.additionalRelatedView}
                  to={item?.url}
                  underline={`true`}
                >
                  {locales?.additionalRelatedView}
                </Button>
              </Content>
            </Column>
          </StyledRow>
        </Item>
      </StyledContainer>
    </Slide>
  )
)
