import React from "react"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"

import { withSearch } from "./withSearch"
import { SearchResults } from "./Results/SearchResults"

export const Search = withSearch(
  ({ page, shopifyPermanentDomain, template }): JSX.Element => (
    <ReactifySearchProvider mode="search" shopifyPermanentDomain={shopifyPermanentDomain ?? ""}>
      <Sensors />
      <SearchResults filterId={`search`} page={page} search template={template} />
    </ReactifySearchProvider>
  )
)
