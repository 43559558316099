import React from "react"
import { graphql } from "gatsby"

import { Search as Page } from "../components/Search/Search"

export const query = graphql`
  query {
    page: sanityPageSearch {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalResults
      additionalResultsPlural
      additionalEmpty
      additionalRelated
      additionalSearch
    }
    template: sanityTemplateCollection {
      additionalLoadMoreButton
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
