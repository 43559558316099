import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Mousewheel, Navigation } from "swiper"

import { Icon } from "../../../Icon/Icon"
import { FeaturedProductsItem } from "../Item/FeaturedProductsItem"
import { StyledContainer } from "../../../Styled/Container"
import { Arrows, ArrowWrapper, Arrow } from "./styledFeaturedProductsSlides"

SwiperCore.use([Autoplay, Mousewheel, Navigation])

export const FeaturedProductsSlides = ({
  autoplay,
  colour = `white`,
  handlePromoClick = null,
  id,
  items,
  locales,
  pretitle = null,
  tag = null,
  top,
}): JSX.Element => (
  <>
    <Swiper
      autoplay={autoplay ? { delay: autoplay, disableOnInteraction: true } : false}
      lazy
      loop
      mousewheel={{
        forceToAxis: true,
        thresholdTime: 1000,
      }}
      navigation={{ prevEl: `#${id}-left`, nextEl: `#${id}-right` }}
      preloadImages={false}
      slidesPerView={1}
      spaceBetween={0}
    >
      {items?.map((item: any) => (
        <SwiperSlide key={item?.id}>
          <FeaturedProductsItem handlePromoClick={handlePromoClick} item={item} pretitle={pretitle} tag={tag} top={top} />
        </SwiperSlide>
      ))}
    </Swiper>
    <ArrowWrapper>
      <StyledContainer>
        <Arrows>
          <Arrow colour={colour} id={`${id}-left`} position={`left`} title={locales?.additionalPrevious}>
            <Icon name={`left`} />
          </Arrow>
          <Arrow colour={colour} id={`${id}-right`} position={`right`} title={locales?.additionalNext}>
            <Icon name={`right`} />
          </Arrow>
        </Arrows>
      </StyledContainer>
    </ArrowWrapper>
  </>
)
